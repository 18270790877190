import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import ElasticCarousel from "react-elastic-carousel";

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 }
]

const Brand = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSlideChange = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    return (
        <>
            <Row className=''>
                <Col lg="12" md="12" className='' >
                    <div className='mac-brand d-md-block d-none '>
                        <img src={require("../../assets/img/Ads_images/about-logos.png").default} height="100%" width="100%" alt="img" className="img-fluid " />
                    </div>
                </Col>
            </Row>

            <section className=' d-block d-md-none '>

                <Row className=" ">
                    <Col sm="12" xs='12' className=''>
                        <ElasticCarousel
                            breakPoints={breakPoints}
                            onSlideChange={handleSlideChange}>

                            <div className="d-flex justify-content-center align-items-center" data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000">
                                <img src={require("../../assets/img/Ads_images/logos 1.png").default} alt="img" className="img-fluid px-2" />
                            </div>
                            <div className="d-flex justify-content-center align-items-center" data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" >
                                <img src={require("../../assets/img/Ads_images/logos 2.png").default} alt="img" className="img-fluid  px-2" />
                            </div>
                            <div className="d-flex justify-content-center align-items-center" data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" >
                                <img src={require("../../assets/img/Ads_images/logos 3.png").default} alt="img" className="img-fluid  px-2" />
                            </div>
                            <div className="d-flex justify-content-center align-items-center" data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000">
                                <img src={require("../../assets/img/Ads_images/logos 4.png").default} alt="img" className="img-fluid  px-2" />
                            </div>
                            <div className="d-flex justify-content-center align-items-center" data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" >
                                <img src={require("../../assets/img/Ads_images/logos 5.png").default} alt="img" className="img-fluid  px-2" />
                            </div>
                        </ElasticCarousel>
                    </Col>
                </Row >

            </section>

        </>
    )
}

export default Brand