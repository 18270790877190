import IndexNavbar from 'components/Navbars/IndexNavbar'
import React from 'react';
import { Row, Col, Button } from "reactstrap"

const Banner = () => {

    return (
        <>

            <div className='banner-img' data-aos="zoom-in" >
                <IndexNavbar />
                <section style={{ padding: "90px 20px 60px 20px" }}>
                    <Row >
                        <Col lg="2" md="2" ></Col>
                        <Col lg="8" md="8" sm="12">
                            <div className="ban-text d-flex flex-column justify-content-center align-items-center ">
                                <div className='text-center'>
                                    <h1 className='mb-0'>Boost and Expand</h1>
                                    <h1 className='mb-0'>Your Business
                                        <span><img src={require("../../assets/img/Ads_images/thunder.png").default} alt="img" className="img-fluid ml-2" /> </span>
                                    </h1>
                                </div>
                                {/* <div className='d-block d-lg-none'>
                                    <h1 className='mb-0 mx-2 mx-md-0 text-center'>Boost and Expand Your Business
                                        <span><img src={require("../../assets/img/Ads_images/thunder.png").default} alt="img" className=" img-fluid ml-2" /> </span>
                                    </h1>
                                </div> */}

                                <div className='d-lg-block d-none'>
                                    <div className='d-flex flex-column justify-content-center align-items-center text-center mt-4'>
                                        <p className=''>Maximize business impact using dynamic digital displays to engage customers, enhance</p>
                                        <p className=''> branding, and drive sales in any environment.</p>
                                    </div>
                                </div>
                                <div className='d-block d-lg-none'>
                                    <div className=' d-flex flex-column justify-content-center align-items-center  mt-4'>
                                        <p className='text-center mx-2'>Maximize business impact using dynamic digital displays to engage customers, enhance branding, and drive sales in any environment.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='ban-text d-flex flex-md-row flex-column justify-content-center align-items-center pt-4'>
                                <button className='red-button my-3 my-md-0' style={{ cursor: 'pointer' }} onClick={() => window.location.href = "https://www.app.adsai.tech/register"}>
                                    <div className='d-flex justify-content-center align-items-center red-button px-3 py-2'>
                                        <span className='mb-0'>START MY TRIAL</span>
                                        <span><img src={require("../../assets/img/Ads_images/white-arrow.png").default} alt="img" className="w-10 img-fluid ml-2 mb-2 mt-2" /> </span>
                                    </div>
                                </button>
                                <a href="/#contact">
                                    <Button className='red-button-outline mx-3 outline-button'>
                                        <div className=' px-1 py-1 d-flex justify-content-center align-items-center'>
                                            <span className='mb-0'>BOOK DEMO</span>
                                        </div>
                                    </Button>
                                </a>
                            </div>
                        </Col>
                        <Col lg="2" md="2" ></Col>
                    </Row>

                    <Row className='mt-5 d-flex justify-content-center align-items-center'>
                        <Col lg="7" md="7" sm="12">
                            <div className="d-flex justify-content-center align-items-center position-relative">
                                <img src={require("../../assets/img/Ads_images/television.png").default} alt="img" className="w-100 img-fluid" />
                                <img src={require("../../assets/img/Ads_images/555.gif").default} alt="img" className="w-100 img-fluid animated-video" />
                            </div>
                        </Col>
                    </Row>

                </section>
            </div >

        </>
    )
}

export default Banner
