import React, { useState } from 'react'
import { Row, Col,Container} from 'reactstrap'
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

const Features = () => {

    const [active, setIsActive] = useState(null)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(null)
        }
        setIsActive(i)
    }
    const data = [{

        question: <div><span className='feature-num'>01.</span> Quick 2 Mins Setup</div>,
        answer:
            <div className="mb-2">
                With our plug-and-play approach, get up and running in seconds. AdsAI Player comes pre-installed with AdsAI’s software, making it quick and easy to deploy, even for large screen networks. Our user-friendly interface makes setup a breeze, so you can focus on creating compelling content for your audience.
                <div className='feature-para-text'>
                    <p className=' my-2'>Learn more about setup
                        <img src={require("../../assets/img/Ads_images/red-arrow.png").default} alt="img" className="img-fluid ml-2" />
                    </p>
                </div>
            </div>

    },
    {
        question: <div><span className='feature-num'>02.</span> Manage Remotely</div>,
        answer: <div className="mb-2">ADsAI provides the ultimate solution for managing your teams, projects, and systems from anywhere in the world.Whether you're coordinating a distributed team, overseeing operations across different locations, or simply need to ensure everything runs smoothly without being on-site.</div>
    },
    {
        question: <div><span className='feature-num'>03.</span> Earn Revenues</div>,
        answer: <div className="mb-2">In a competitive market, finding and optimizing revenue streams is crucial for growth and sustainability. Our software is designed to empower businesses and individuals to discover, manage, and maximize revenue opportunities efficiently. From identifying new income sources to optimizing existing ones, ADsAI provides the tools and insights you need to drive financial success.</div>
    }

    ]

    return (
        <div className='feature-bg pb-5' id='features'>

            <Container>
                <Row className=' pt-5 pb-3'>
                    <Col lg="6" className='feature-text ' data-aos="fade-right">
                        <div className='d-lg-block d-none'>
                            <div className=' d-flex flex-column justify-content-lg-start justify-content-center align-items-lg-start align-items-center'>
                                <h3 className=' text-left '>Powerful Features
                                    <span><img src={require("../../assets/img/Ads_images/Fire.png").default} alt="img" className="img-fluid ml-2" /></span></h3>
                                <p >Empowering digital signage with robust features, enhancing </p>
                                <p>communication impact and flexibility for dynamic content</p>
                                <p>delivery and management solutions.</p>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                            <div className=' d-flex flex-column justify-content-center align-items-center'>
                                <h3 className=' text-center '>Powerful Features
                                    <span><img src={require("../../assets/img/Ads_images/Fire.png").default} alt="img" className="img-fluid ml-2" /></span></h3>
                                <p className=' text-center mx-4' >Empowering digital signage with robust features, enhancing
                                    communication impact and flexibility for dynamic content
                                    delivery and management solutions.
                                </p>
                            </div>
                        </div>

                        {data.map((item, i) => (
                            <div className="mt-4  feature-text" >
                                <div className=" feature-border p-4 d-flex justify-content-between align-items-center" onClick={() => toggle(i)}>
                                    <h4 className=" mb-0">
                                        {item.question}
                                    </h4>
                                    {active === i ? <AiOutlineMinus color="#DE2729" size="30" className="img-fluid cursor pointer" /> : < AiOutlinePlus color="#DE2729" size="30" className="img-fluid cursor pointer" />}
                                </div>
                                <div className={` faq-ans  ${active === i ? 'content show px-5' : 'content px-5'}`}>
                                    <p className="  mb-0">
                                        {item.answer}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Col>
                    <Col lg="6" className=' ' data-aos="fade-left">
                        <div className='pt-5 pt-lg-0 mt-lg-0 mt-5 d-flex flex-column justify-content-lg-end justify-content-center align-items-lg-end align-items-center'>

                            <img src={require('../../assets/img/Ads_images/features-img.png').default} height={"90%"} width={"90%"} className="img-fluid " />

                        </div>

                    </Col>
                </Row>

            </Container>

        </div >
    )
}

export default Features