import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { Row, Col, Container } from 'reactstrap'
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"


const FAQ = () => {

    const [active, setIsActive] = useState(null)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(null)
        }
        setIsActive(i)
    }
    const data = [{
        question: 'What is digital signage software?',
        answer: <div className="mb-2">Digital signage software is a type of software used to create, manage, and display digital content on electronic screens such as LCD, LED, or projection screens. This content can include advertisements, news, social media feeds, videos, and other multimedia elements. Digital signage is commonly used in public spaces, businesses, retail environments, and corporate settings to communicate messages effectively to an audience.</div>

    },
    {
        question: 'How does digital signage software work?',
        answer: <div className="mb-2">Users create content using built-in design tools or external applications. This content can include text, images, videos, animations, and interactive elements. Many digital signage platforms offer pre-designed templates to simplify the content creation process.  Users manage content from a centralized dashboard or control panel. This interface allows for organizing, scheduling, and modifying content. Content is played back on digital signage players (hardware or software) connected to the screens. These players receive content from the management system and handle the display of the media.</div>
    },
    {
        question: 'What features should I look for in digital signage software?',
        answer: <div className="mb-2">When selecting digital signage software, you should look for features such as easy content management, playlist creation, and scheduling capabilities. Ensure the software allows customization of content, prioritization of ads, and seamless integration with various types of displays. Look for user-friendly interfaces and robust support options to assist with troubleshooting and maintaining your displays.</div>

    },
    {
        question: 'Can I customize content with digital signage software?',
        answer: <div className="mb-2">Yes, with Ads AI Signage, you can easily customize your content. Our software allows you to manage and prioritize ads, images, videos, and GIFs, and to adjust the sequence of your content. You can also update and modify your content in just a few simple steps, making it convenient to tailor your display to your needs.</div>
    },
    {
        question: 'Is digital signage software compatible with different types of displays?',
        answer: <div className="mb-2">Absolutely. Ads AI Signage is designed to be compatible with any display that supports Android. Whether you're using a single screen or multiple screens in various setups, our software integrates seamlessly to manage and display your content effectively.</div >
    },
    {
        question: 'How easy is it to update content using digital signage software?',
        answer: <div className="mb-2">Updating content with Ads AI Signage is very straightforward. You can quickly make changes to your media content, adjust playlists, and schedule updates with minimal effort. Our intuitive interface ensures that you can manage your content efficiently and keep your displays up to date without hassle.</div>
    },
    {
        question: ' What kind of support is available for troubleshooting issues with digital signage software?',
        answer: <div className="mb-2">We offer comprehensive support for troubleshooting issues with Ads AI Signage. Our support includes phone assistance, video call support, and chat support, ensuring you have multiple ways to get help when you need it. Our team is ready to assist you with any challenges you encounter to ensure smooth operation of your digital signage.</div>
    },
    {
        question: ' Can I schedule content to display at specific times with digital signage software?',
        answer: <div className="mb-2">Yes, Ads AI Signage provides robust scheduling features. You can easily schedule when and how your content is displayed, allowing you to set specific times for different media to appear. This ensures that your ads and information are shown at the most effective times for your audience.</div>
    }]

    return (
        <div className=' faq-bg py-5' >
            <Container >
                <section className=' faq-section '>
                    <div className=" faq-text mx-4" id='faqs'>
                        <h3 className=' text-center pb-4 mb-0 mt-4 mt-md-0 '>Frequently asked questions</h3>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="10">
                                {data.map((item, i) => (
                                    <div className="mt-4 faq-content p-3" data-aos="fade-down-left" >
                                        <div className=" d-flex justify-content-between align-items-center" onClick={() => toggle(i)}>
                                            <h5 className=" mb-0 text-dark">
                                                {item.question}
                                            </h5>
                                            {active === i ? <AiOutlineMinus color="#DE2729" size="30" className="img-fluid cursor pointer faq-control" /> : <AiOutlinePlus color="#DE2729" size="30" className="img-fluid cursor pointer faq-control" />}
                                        </div>
                                        <div className={`faq-ans w-100 ${active === i ? 'content show pt-3' : 'content'}`}>
                                            <p className=" mb-0 text-dark">
                                                {item.answer}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                            <Col lg="1"></Col>
                        </Row>
                    </div>

                </section>
            </Container>
        </div>
    )
}

export default FAQ