import { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Card, FormGroup, Label, Input, Button, Row, Col, InputGroup, Modal, ModalBody } from 'reactstrap'

const Signup = ({ signupModal, setSignupModal, loginModal, setLoginModal }) => {
    const [inputVisibility, setInputVisibility] = useState(false)

    const { control, handleSubmit, formState: { errors } } = useForm()

    const toggleInputVisibility = () => {
        setInputVisibility(!inputVisibility)
    }

    const onSubmit = (data) => {
        console.log(data)
    }

    return (
        <Modal isOpen={signupModal} toggle={() => setSignupModal(!signupModal)}>
            <ModalBody>
                <Row>
                    <Col lg='12' md='12' sm='12' xs='12' className='pt-5 pt-lg-3 pt-md-5 px-5'>

                        <div className='d-flex justify-content-center mb-3'>
                        <img src={require('../../assets/img/Ads_images/Logo.png').default} width={'40%'} alt="Logo" />
                        </div>
                        <h4 className='text-center text-dark mt-4 mb-4'>Sign up for an account</h4>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup className='mb-3'>
                                <Label for='fname'>First Name</Label>
                                <Controller
                                    control={control}
                                    name="fname"
                                    rules={{ required: 'First Name is required' }}
                                    render={({ field }) => (
                                        <Input
                                            autoFocus
                                            type='text'
                                            id='fname'
                                            placeholder='Enter First Name'
                                            invalid={!!errors.fname}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.fname && <span className='text-danger'>{errors.fname.message}</span>}
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for='lname'>Last Name</Label>
                                <Controller
                                    control={control}
                                    name="lname"
                                    rules={{ required: 'Last Name is required' }}
                                    render={({ field }) => (
                                        <Input
                                            type='text'
                                            id='lname'
                                            placeholder='Enter Last Name'
                                            invalid={!!errors.lname}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.lname && <span className='text-danger'>{errors.lname.message}</span>}
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for='email'>Email</Label>
                                <Controller
                                    control={control}
                                    name="email"
                                    rules={{ required: 'Email is required' }}
                                    render={({ field }) => (
                                        <Input
                                            type='email'
                                            id='email'
                                            placeholder='Enter Email'
                                            invalid={!!errors.email}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.email && <span className='text-danger'>{errors.email.message}</span>}
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for='password'>Password</Label>
                                <InputGroup className='d-flex align-items-center border'>
                                    <Controller
                                        control={control}
                                        name="password"
                                        rules={{ required: 'Password is required' }}
                                        render={({ field }) => (
                                            <Input
                                                className='border-0'
                                                type={inputVisibility ? 'text' : 'password'}
                                                id='password'
                                                placeholder='Enter Password'
                                                invalid={!!errors.password}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {inputVisibility
                                        ? <EyeOff onClick={toggleInputVisibility} className='mr-3' size={14} />
                                        : <Eye onClick={toggleInputVisibility} className='mr-3' size={14} />}
                                </InputGroup>
                                {errors.password && <span className='text-danger'>{errors.password.message}</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label for='confirm-password'>Confirm Password</Label>
                                <InputGroup className='d-flex align-items-center border'>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        rules={{ required: 'Confirm Password is required' }}
                                        render={({ field }) => (
                                            <Input
                                                className='border-0'
                                                type={inputVisibility ? 'text' : 'password'}
                                                id='confirm-password'
                                                placeholder='Enter Confirm Password'
                                                invalid={!!errors.confirmPassword}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {inputVisibility
                                        ? <EyeOff onClick={toggleInputVisibility} className='mr-3' size={14} />
                                        : <Eye onClick={toggleInputVisibility} className='mr-3' size={14} />}
                                </InputGroup>
                                {errors.confirmPassword && <span className='text-danger'>{errors.confirmPassword.message}</span>}
                            </FormGroup>
                            <Button type="submit" className="mt-4 mb-2 btn btn-danger" block>
                                SIGN UP
                            </Button>

                            <p className='text-center mt-4'>
                                <span className='mr-25 text-dark'>Already have an account? </span>

                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                    setSignupModal(!signupModal)
                                    setLoginModal(!loginModal)
                                }} className='text-primary'>Login</span>

                            </p>
                        </form>

                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default Signup
