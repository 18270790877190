import React from "react";

import Banner from "./Banner";
import Brand from "./Brand"
import About from "./About";
import Pricing from "./Pricing";
import Features from "./Features";
import Contact from "./Contact";
import FAQ from "./FAQ";
import Footer from "components/Footer/Footer";


const Home = () => {
    return (
        <>
            <div className="px-0">
                <Banner />
                <Brand/>
                <About />
                <Features />
                {/* <Pricing /> */}
                <FAQ />
                <Contact />
                <Footer />
            </div>
        </>
    )
}

export default Home