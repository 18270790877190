import IndexNavbar from 'components/Navbars/IndexNavbar'
import React from 'react'
import { Row, Col } from "reactstrap"

const TermsAndCondition = () => {

    return (
        <>
            <IndexNavbar />
            <section>
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col lg="1"></Col>
                    <Col lg="10">
                        <h2 className='text-dark text-center'>Terms and Conditions</h2>
                        <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </Col>
                    <Col lg="1"></Col>
                </Row>
            </section>
            <div>

                <Row className="d-flex justify-content-center align-items-center border-footer pt-3 pb-3 foot-text mt-5 mb-1" >
                    <Col lg="3" md="3" >

                    </Col>
                    <Col md="6" lg="6" className=" text-center  text-uppercase">
                        <p className="mb-0">© Copyright 2024. Ads AI. All rights reserved.</p>
                    </Col>
                    <Col lg="3" md="3" >

                    </Col>

                </Row>

            </div>
        </>
    )
}

export default TermsAndCondition