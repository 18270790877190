
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";

import "assets/css/nucleo-icons.css";
import "assets/scss/styles.scss?v=1.2.0";
import "assets/demo/demo.css";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// Configuration options for react-alert
const options = {
  
  position: positions.TOP_RIGHT,
  timeout: 50000
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Routes />
  </AlertProvider>,

  document.getElementById('root')
);

