import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  ModalFooter,
  Input,
  Card,
  InputGroup,
} from "reactstrap";

import { FiMenu } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa';
import { Controller, useForm } from "react-hook-form";
import { Eye, EyeOff } from "react-feather";
import Login from "views/authentication/Login";
import Signup from "views/authentication/Signup";

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  const [inputVisibility, setInputVisibility] = useState(false)

  const { control, register, handleSubmit, formState: { errors } } = useForm()

  const toggleInputVisibility = () => {
    setInputVisibility(!inputVisibility)
  }

  const onSubmit = (data) => {
    console.log('data', data)
    return false
  }

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <div className="navbar pr-0 pl-0" id="home">
      <Navbar expand="xl" className="py-0">
        <Row className="w-100">
          <Col lg="12" xl="2" className="px-0 px-xl-3">
            <div className="d-flex w-100 justify-content-between">
              <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require('../../assets/img/Ads_images/Logo.png').default}
                    height={160}
                    width={160}
                    className="img-fluid ml-lg-5 ml-0"
                    alt="Logo"
                  />
                </div>
              </NavbarBrand>
              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-dark" size="24" />
              </button>
            </div>
          </Col>
          <Col lg="4" xl="10" className="pr-0 d-flex justify-content-center align-items-center">
            <Collapse
              className={`ml-4 bg-black justify-content-center align-items-center ${collapseOut}`}
              navbar
              isOpen={collapseOpen}
              onExiting={onCollapseExiting}
              onExited={onCollapseExited}
            >
              <Row className="navbar-collapse-header">
                <Col className="collapse-close text-right d-flex justify-content-end align-items-end" xs="12">
                  <button
                    aria-expanded={collapseOpen}
                    className="navbar-toggler"
                    onClick={toggleCollapse}
                  >
                    <FaTimes className="pf-text-secondary" color="white" size="24" />
                  </button>
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column flex-xl-row w-100 justify-content-center mobile-menu nav-text">
                  <Nav navbar className="nav-text-size">
                    <NavItem className="p-2">
                      <NavLink href="/" className="mr-2">
                        HOME
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink href="/#about" className="mr-2">
                        ABOUT
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink href="/#features" className="mr-2">
                        FEATURES
                      </NavLink>
                    </NavItem>
                    {/* <NavItem className="p-2">
                      <NavLink href="/#pricing" className="mr-2">
                        PRICING
                      </NavLink>
                    </NavItem> */}
                    <NavItem className="p-2">
                      <NavLink href="/#faqs" className="mr-2">
                        FAQS
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink href="/#contact" className="mr-2">
                        CONTACT
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <Row>
                    <Col className="d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start pl-xl-5 pl-2 ml-xl-5 ml-2">
                      <div className="pt-4 pt-lg-0 pb-4 pb-lg-0 nav-text-size ml-2">
                        <h5 className="mb-0" style={{ cursor: 'pointer' }} onClick={() => window.location.href="https://www.app.adsai.tech/login" }>
                          LOGIN
                        </h5>
                      </div>
                      <div className="my-3 my-xl-0 ml-lg-5 ml-2 text-nowrap">

                        <button onClick={() => window.location.href="https://www.app.adsai.tech/register" } className="py-2 text-white font-weight-bold px-4 red-button">
                          SIGN UP
                        </button>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </Col>
        </Row>
      </Navbar>

      <Login loginModal={loginModal} setLoginModal={setLoginModal} signupModal={signupModal} setSignupModal={setSignupModal} />
      <Signup signupModal={signupModal} setSignupModal={setSignupModal} loginModal={loginModal} setLoginModal={setLoginModal} />
    </div>
  );
}
