import React from 'react'
import { Row, Col, Container } from "reactstrap"

const About = () => {
    return (
        <>
            <section data-aos="zoom-out" >
                <Row id='about'>
                    <Col lg="2" md="2" className=''></Col>
                    <Col lg="8" md="8" className='py-2 text-center' >

                        <div className=' about-text d-lg-block d-none'>
                            <div className='mb-2'>
                                <h3 className='mb-0  text-center '>
                                    We're Digital Signage
                                </h3>
                                <h3 className='mb-0  text-center '>
                                    Software Expert!
                                </h3>
                            </div>
                            <p className=''>
                                We excel in digital signage software solutions, providing expertise in design,
                            </p>
                            <p className=''>
                                implementation, and management. Tailored to diverse needs, our services ensure
                            </p>
                            <p className=''>
                                impactful and seamless integration for dynamic display solutions.
                            </p>

                            <div className="mt-4 text-center ">
                                <a href="/#contact">
                                    <button className=" py-3 px-4 outline-button red-button-outline text-uppercase"><span className='mb-0'>schedule my demo</span>
                                    </button>
                                </a>
                            </div>
                        </div>

                        <div className='py-lg-5 py-0  about-text d-block d-lg-none'>
                            <div className='mb-2'>
                                <h3 className='mb-0  text-center '>
                                    We're Digital Signage Software Expert!
                                </h3>
                            </div>
                            <p className='text-center'>
                                We excel in digital signage software solutions, providing expertise in design
                                implementation, and management. Tailored to diverse needs, our services ensure
                                impactful and seamless integration for dynamic display solutions.
                            </p>

                            <div className="mt-4 text-center ">
                                <a href="/#contact">
                                    <button className=" py-3 px-4 outline-button red-button-outline text-uppercase"><span className='mb-0'>schedule my demo</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="2" md="2" className='py-2' data-aos="zoom-in"></Col>
                </Row>

            </section>
        </>
    )
}
export default About