
import React, { useState } from "react";
// reactstrap components
import {
  Row,
  Col, Container, Input
} from "reactstrap";

import { AnchorScroll } from "helper";
import { FaTimes, FaTwitter, FaDiscord, FaArrowAltCircleUp, FaArrowCircleUp, FaArrowCircleLeft, FaArrowCircleRight, FaArrowAltCircleRight } from 'react-icons/fa'
import { useAlert } from "react-alert";

export default function Footer() {
  const alert = useAlert();
  const [email, setEmail] = useState(null)

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = async () => {
    if (email === null || email === "") {
      alert.error("Please enter email!");
      return false
    }
    if (!validateEmail(email)) {
      alert.error("Please enter valid email!");
      return false
    }
    window.location.href = `https://www.app.adsai.tech/register/?email=${encodeURIComponent(email)}`
  }

  return (
    <>
      <div data-aos="flip-up">
        <Row className=" foot-text border-footer">
          <Col md="1" lg="1" className=""></Col>
          <Col md="7" lg="7" className=" pt-5 pb-5 d-flex justify-content-lg-start justify-content-center align-items-center" >
            <h3 className="mb-0 mt-3 mx-4 mx-md-0">Everything begins with an idea.</h3>
          </Col>
          <Col md="2" lg="2" className=""></Col>
          {/* <Col md="2" lg="2" className="d-flex justify-content-center align-items-center foot-logo">
            <img src={require('../../assets/img/Ads_images/facebook.png').default} className="img-fluid foot-logo mr-2" height={"40%"} width={"40%"} />
            <img src={require('../../assets/img/Ads_images/twitter.png').default} className="img-fluid foot-logo mr-2  ml-2" height={"40%"} width={"40%"} />
            <img src={require('../../assets/img/Ads_images/insta.png').default} className="img-fluid foot-logo ml-2" height={"40%"} width={"40%"} />
          </Col> */}
          <Col md="2" lg="2" className=""></Col>
        </Row>

        <Row>
          <Col md="1" lg="1" className=""></Col>
          <Col lg="5" md="5" className="">
            <div className="py-5 py-lg-0 mt-3 emailField">
              <div className='d-flex justify-content-lg-start justify-content-center align-items-center'>
                <Input
                  type="email"
                  placeholder='Enter Your Email'
                  className=' input-foot'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                >
                </Input>
                <button className=" py-2 px-4 red-button "
                  onClick={handleSubmit}
                  style={{ marginLeft: '-7.7rem' }}>
                  <span className="text-nowrap">Get started</span></button>
              </div>
            </div>
          </Col>


          <Col md="3" lg="3" className="pt-5 pt-md-3 pt-lg-0 d-flex flex-column justify-content-start text-center text-lg-left foot-text  pb-3 " >
            <h5 className="text-nowrap text-dark">
              Important Links
            </h5>

            <a href="/#about" >
              <p className="text-dark">
                About
              </p>
            </a>

            <a href="/#features" >
              <p className="text-dark">
                Features
              </p>
            </a>

            {/* <a href="/#pricing">
              <p className="text-dark">
                Pricing
              </p>
            </a> */}

            <a href="/#contact">
              <p className="text-dark">
                Contact
              </p>
            </a>
          </Col>


          <Col md="3" lg="3" className=" pt-3 pt-lg-0 foot-text  pb-3">

            <div className="d-flex flex-column align-items-lg-start justify-content-lg-start justify-content-center align-items-center">
              <h5 className="text-nowrap text-dark">
                Useful Links
              </h5>

              {/* <a href="/privacy" >
                <p className="text-dark">
                  Privacy policy
                </p>
              </a>

              <a href="/terms" >
                <p className="text-dark">
                  Terms & conditions
                </p>
              </a> */}

              <a href="#faqs">
                <p className="text-dark">
                  FAQ
                </p>
              </a>

              <a href="https://www.app.adsai.tech/login">
                <p className="text-dark">
                  Log in
                </p>
              </a>
            </div>

          </Col>
        </Row >
        <div>

          <Row className="d-flex justify-content-center align-items-center border-footer pt-3 pb-3 foot-text mt-5 mb-1" >
            <Col lg="3" md="3" >

            </Col>
            <Col md="6" lg="6" className=" text-center  text-uppercase">
              <p className="mb-0">© Copyright 2024. Ads AI. All rights reserved.</p>
            </Col>
            <Col lg="3" md="3" >

            </Col>

          </Row>

        </div>
      </div>
    </>
  );
}
