import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Col, Row, Form, FormGroup, Input, Container } from 'reactstrap'
import { useAlert } from "react-alert";

const Contact = () => {
    const { control, formState: { errors }, handleSubmit, reset } = useForm()
    const [btnSubmitted, setBtnSubmitted] = useState(false)
    const history = useHistory()
    const alert = useAlert()
    
    const resetForm = () => {
        reset({
          name: '',
          email: '',
          phone: '',
          message: ''
        })
    }

    const onSubmit = (data) => {

        const params = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message
        }

        setBtnSubmitted(true)

        fetch('https://a1.adsai.tech/api/v1/contact-us/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((res) => {
                setBtnSubmitted(false)
                
                if (res.status === true) {
                    alert.success("Thank you for getting in touch. We will respond to you very soon!");
                    resetForm()
                }
            })
            .catch((error) => {
                setBtnSubmitted(false)
                console.error(error)
            })
    }

    return (
        <>
            <section id='contact'>
                <Container>
                    <Row className="mt-4 ml-2 mr-2">
                        <Col lg="6" md="12" data-aos="fade-left">
                            <div className="d-flex justify-content-center align-items-center">
                                <img
                                    src={require("../../assets/img/Ads_images/Support.png").default}
                                    alt="img"
                                    className="img-fluid px-3"
                                    height={"100%"}
                                    width={"100%"}
                                />
                            </div>
                        </Col>
                        <Col lg="6" md="12" data-aos="fade-right">
                            <div className="d-lg-block d-none">
                                <div className="pt-5 pt-lg-0 mt-lg-2 mt-5 support-text d-flex flex-column justify-content-lg-start justify-content-center align-items-center align-items-lg-start">
                                    <h3 className="mb-0">We've been waiting</h3>
                                    <h3 className="mb-0 text-lg-left text-center">for you</h3>
                                    <p className="py-3">We want to hear from you. Let us know how we can help.</p>
                                </div>
                            </div>
                            <div className="d-block d-lg-none">
                                <div className="mt-lg-0 mt-5 support-text d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="mb-0 text-center">We've been waiting for you</h3>
                                    <p className="py-3 text-center">We want to hear from you. Let us know how we can help.</p>
                                </div>
                            </div>
                            <Form className='contact-form' onSubmit={handleSubmit(onSubmit)} >
                                <div className="mb-2">
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                id="Name"
                                                placeholder="Name"
                                                type="text"
                                                className={`${errors.name ? 'is-invalid' : ''}`}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.name && (
                                        <span className="text-danger mb-0">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                id="Email"
                                                placeholder="Email"
                                                type="email"
                                                className={`${errors.email ? 'is-invalid' : ''}`}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.email && (
                                        <span className="text-danger mb-0">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                id="Phone"
                                                placeholder="Phone"
                                                type="text"
                                                className={`${errors.phone ? 'is-invalid' : ''}`}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <span className="text-danger mb-0">
                                            {errors.phone.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <Controller
                                        name="message"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                id="Text"
                                                placeholder="  Message"
                                                type="textarea"
                                                style={{ height: '200px', width: '100%', resize: 'none' }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <FormGroup>
                                    <button
                                        className="red-button submit-button px-5 py-3 "
                                        disabled={btnSubmitted}
                                        type="submit"
                                    >
                                        <span className="mb-0 d-flex justify-content-center text-uppercase">
                                            Submit
                                            <span>
                                                <img
                                                    src={require("../../assets/img/Ads_images/white-arrow.png").default}
                                                    alt="img"
                                                    className="img-fluid ml-2"
                                                />
                                            </span>
                                        </span>
                                    </button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Contact


