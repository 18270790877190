import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Pricing = () => {
    return (
        <>
            <Container id='pricing'>
                <div className='d-flex align-items-center justify-content-center mt-5 ' id="nfts">

                    <div className='d-flex flex-column align-items-center justify-content-center nft-text'>
                        <h3 className=' text-nowrap mb-0 mx-4'>Let’s get started </h3>
                        <p className='my-4'>Pick the plan tailored for your business.</p>

                        <div className="d-flex flex-column align-items-center justify-content-center  ">
                            <a href='#' className=''>
                                <button className=" py-2 px-4 red-button word-nowrap "><span>Monthly</span></button>
                                <button className=" py-2 px-4 Annually-button red-button-outline word-nowrap "><p className="annually-text mb-0">Annually</p></button>
                            </a>
                        </div>
                    </div>
                </div>

                <Row className='mt-5 mx-2'>
                    <Col lg="4" className=' d-flex align-items-center justify-content-center ' data-aos="flip-left" >
                        <div className="my-lg-0 my-3  card nft-card card-hover" >
                            <div className="card-body ">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h4 className="card-title ">Basic</h4>
                                    <h6 className=' text-capitalize'>Start</h6>
                                </div>
                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                    <p className="card-text">Ideal to test it out</p>
                                    <h3 className=' mb-0'>₹999<span className='span-text'>/per month</span></h3>
                                </div>
                            </div>
                            <button className=" py-2 px-4 mx-3 red-button word-nowrap text-uppercase"><span className='span-para'><p className='mb-0 text-white'>choose plan</p></span></button>
                            <div className='card-border nft-card px-3 mt-3'>
                                <h5 className='mb-0'>Features</h5>
                            </div>
                            <div className=' d-flex '>
                                <div className='p-3 '>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>1 advertisement</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Plan for 1 month</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Ads playback time up to 10sec</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>5 stations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg="4" className=' d-flex align-items-center justify-content-center'>
                        <div className="my-lg-0 my-3 card nft-card card-hover" >

                            <div className="card-body ">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h4 className="card-title ">Plus</h4>
                                    <h6 className=' text-capitalize'>Regular</h6>
                                </div>
                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                    <p className="card-text">Best for Professionals</p>
                                    <h3 className='mb-0'>₹3999<span className='span-text'>/per month</span></h3>
                                </div>

                            </div>
                            <button className=" py-2 px-4 mx-3 red-button word-nowrap text-uppercase"><span className='span-para'><p className='mb-0 text-white'>choose plan</p></span></button>
                            <div className='card-border px-3 mt-3'>
                                <h5 className='mb-0'>Features</h5>
                            </div>
                            <div className=' d-flex '>
                                <div className='p-3 '>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Up to 5 advertisements</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Plan for 1 month</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Ads playback time up to 20sec</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>15+ stations</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>

                    <Col lg="4" className='d-flex align-items-center justify-content-center' data-aos="flip-right">
                        <div className="my-lg-0 my-3 card nft-card card-hover" >

                            <div className="card-body ">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h4 className="card-title ">Business</h4>
                                    <h6 className=' text-capitalize'>Scale</h6>
                                </div>
                                <div className='d-flex flex-column align-items-start justify-content-start'>
                                    <p className="card-text">To grow your business</p>
                                    <h3 className='mb-0'>₹7999<span className='span-text'>/per month</span></h3>
                                </div>
                            </div>
                            <button className=" py-2 px-4 mx-3 red-button word-nowrap text-uppercase"><span className='span-para'><p className='mb-0 text-white'>choose plan</p></span></button>
                            <div className='card-border px-3 mt-3'>
                                <h5 className='mb-0'>Features</h5>
                            </div>
                            <div className='parent-div d-flex '>
                                <div className='p-3 '>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Up to 12 advertisements</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Plan for 1 month</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>Ads playback time up to 25sec</p>
                                    </div>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <img src={require("../../assets/img/Ads_images/red-tick.png").default} alt="img" className="img-fluid mt-1" />
                                        <p className='ml-2 mb-3 text-dark'>30+ stations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Pricing