import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
    Card, Form, FormGroup, Label, Input, Button, Row, Col, InputGroup, Modal, ModalBody
} from 'reactstrap';

const Login = ({ loginModal, setLoginModal, signupModal, setSignupModal }) => {
    const [inputVisibility, setInputVisibility] = useState(false);

    const { control, handleSubmit, formState: { errors } } = useForm();

    const toggleInputVisibility = () => {
        setInputVisibility(!inputVisibility);
    };

    const onSubmit = (data) => {
        console.log('Submitted Data:', data);

    };

    return (
        <>
            <Modal isOpen={loginModal} toggle={() => setLoginModal(!loginModal)}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col lg='12' md='12' sm='12' xs='12' className='py-3 px-5'>

                                <div className='d-flex justify-content-center mb-4'>
                                    <img src={require('../../assets/img/Ads_images/Logo.png').default} width={'40%'} alt="Logo" />
                                </div>
                                <h4 className='text-center text-dark mt-4 mb-4'>Please login with your account below</h4>
                                <FormGroup className='mb-4'>
                                    <Label for='lemail'>Email</Label>
                                    <Controller
                                        control={control}
                                        name="lemail"
                                        rules={{ required: 'Email is required' }}
                                        render={({ field }) => (
                                            <Input
                                                autoFocus
                                                type='text'
                                                id='lemail'
                                                placeholder='Enter Email'
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.lemail && <span className='text-danger'>{errors.lemail.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for='login-password'>Password</Label>
                                    <InputGroup className='d-flex align-items-center border'>
                                        <Controller
                                            control={control}
                                            name="lpassword"
                                            rules={{ required: 'Password is required' }}
                                            render={({ field }) => (
                                                <Input
                                                    className='border-0'
                                                    type={inputVisibility ? 'text' : 'password'}
                                                    id='login-password'
                                                    placeholder='Enter Password'
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {inputVisibility
                                            ? <EyeOff onClick={toggleInputVisibility} className='mr-3' size={14} />
                                            : <Eye onClick={toggleInputVisibility} className='mr-3' size={14} />
                                        }
                                    </InputGroup>
                                    {errors.lpassword && <span className='text-danger'>{errors.lpassword.message}</span>}
                                </FormGroup>

                                <Button type="submit" className="mt-5 mb-2 btn btn-danger" block>
                                    LOGIN
                                </Button>

                                <p className='text-center mt-4'>
                                    <span className='mr-25 text-dark'>New to our platform? </span>

                                    <span style={{ cursor: 'pointer' }} onClick={() => {
                                        setLoginModal(!loginModal)
                                        setSignupModal(!signupModal)
                                    }} className='text-primary'>Create an account</span>

                                </p>

                            </Col>
                        </Row>
                    </ModalBody>
                </Form>
            </Modal>
        </>
    );
}

export default Login;
